import styled from 'styled-components'

import { COLORS } from '../../constants/styles'

const SearchBarStyles = styled.div`
  .container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 500px) {
      .container {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: red !important;
      }
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: start;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .button-container {
    display: flex;

    @media only screen and (max-width: 632px) {
      width: 100%;
      justify-content: center;
    }
  }

  .button-input {
    background-color: ${(props) => props.primaryColor};
    width: 30vh;
    border-radius: 10px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .button-input > span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    height: 100%;
    color: ${COLORS.WHITE};
  }

  .title-text {
    display: flex;
    font-family: "Poppins", sans-serif;
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: bold;
    color: ${(props) => props.secondaryColor};

    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  .tracking-icon {
    min-width: 130px !important;
    max-width: 130px !important;
    margin-right: 2vh;

    @media only screen and (max-width: 768px) {
      min-width: 100px !important;
      max-width: 100px !important;
    }
  }

  .search-bar-input {
    display: flex;
  }

  input {
    position: relative;
    border-radius: 10px;
    width: 50vh;
    border: 2px solid ${COLORS.WHITE};

    @media only screen and (max-width: 768px) {
      width: 40vh;
      padding: 0;
    }

    @media only screen and (max-width: 632px) {
      width: 100%;
    }
  }

  input:focus {
    outline: none;
    border: 2px solid ${(props) => props.primaryColor};
  }

  input {
    padding-left: 6vh;
    font-size: 17px;
    padding: 3vh 0 3vh 5vh;

    @media only screen and (max-width: 768px) {
      padding: 2vh 0 2vh 5vh;
    }
  }

  .search-bar-icon {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .search-bar-container-full {
    display: flex;
    justify-content: center;
    gap: 3vh;

    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
    }

    @media only screen and (max-width: 632px) {
      flex-direction: column;
      gap: 2vh;
    }
  }

  .icon-input {
    position: absolute;
    align-items: center;
    padding-left: 1vh;
    width: 4vh;

    @media only screen and (max-width: 768px) {
      width: 3.5vh;
    }
  }

  .icon-information {
    width: 7vh;
    height: 7vh;
    margin-top: 7px;
    margin-left: 7px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      height: 5vh;
      margin-left: 3px;
      padding: 0;
    }
  }

  .modal-text {
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  .images-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .image-modal {
    width: 40%;
    margin-bottom: 30px;
    aspect-ratio: 3/2;
    object-fit: contain;
    @media only screen and (max-width: 768px) {
      width: 60%;
    }
  }

  .error {
    background-color: #f0394d;
    display: none;
    /* display: none; */
    margin: 2vh 0 0 0;
    padding: 2vh 2vh 2vh 2vh;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    border-radius: 10px;
  }

  .loader {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reCaptcha {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }

  .search-bar-container {
    display: flex;
    flex-direction: column
  }

  .captcha-error {
    color: red;
    font-size: 0.8rem;
  }
`

SearchBarStyles.defaultProps = {
  focusColor: (props) => props.primaryColor
}

export default SearchBarStyles
