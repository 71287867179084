import axios from 'axios'

import { BUCKET_URL, PINFLAG_PRIMARY_COLOR, TRACKING_FULL_ICON } from './constants/urls'

export const moneyFormat = (number) =>
  `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`

export const locationFormat = (loc) =>
  loc ? `${loc.slice(0, 3)}-${loc.slice(3, 5)}-${loc.slice(5)}` : '-'

export const toClientState = (state) => {
  const mapper = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 4,
    6: 4,
    7: 5
  }

  return mapper[parseInt(state, 10)]
}

export const login = (token) => {
  const loginToken = token

  localStorage.setItem('token', loginToken)
}

export const getIconData = async (companyPersonalization) => {
  const { baseIconPath } = companyPersonalization

  const timestamp = companyPersonalization.timestamp
    ? `_${companyPersonalization.timestamp}`
    : ''

  try {
    const iconName = TRACKING_FULL_ICON.replace(/\.[^/.]+$/, '')

    await axios.get(`${BUCKET_URL}/${baseIconPath}/${iconName}${timestamp}.png`)

    return { baseIconPath, timestamp }
  } catch (error) {
    return { baseIconPath: 'icons', timestamp: '' }
  }
}


export const setCompanyInfo = async (companyInfoResponse) => {
  const primaryColor = companyInfoResponse.primaryColor ?
    companyInfoResponse.primaryColor : PINFLAG_PRIMARY_COLOR
  const secondaryColor = companyInfoResponse.secondaryColor ?
    companyInfoResponse.secondaryColor : '#173554'
  const { baseIconPath, timestamp } = await getIconData(companyInfoResponse)

  localStorage.setItem('baseIconPath', baseIconPath)
  localStorage.setItem('primaryColor', primaryColor)
  localStorage.setItem('secondaryColor', secondaryColor)
  localStorage.setItem('timestamp', timestamp)
}
