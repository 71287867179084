import styled from 'styled-components'

import { COLORS } from '../../../../constants/styles'

import RawProductCard from './RawProductCard'

const primaryColor = localStorage.getItem('primaryColor')

const ProductCard = styled(RawProductCard)`
  ul {
    list-style-type: none;
    margin: 0;
  }

  .product-detail {
    border-bottom: 1px solid ${COLORS.WHITE};
  }

  .container-photos {
    padding-top: 2.5vh;
  }

  .photos {
    display: flex;
    justify-content: center;
    height: 20vh;
    padding: 1em 0;
  }

  .product-img {
    display: flex;
    position: relative;
    max-width: 150px;
    height: auto;
    z-index: -1;
  }

  .icon {
    width: 2.5em;
    cursor: pointer;
  }

  .right {
    display: flex;
    align-items: center;
    padding-left: 4vh;
  }

  .left {
    display: flex;
    align-items: center;
    padding-right: 4vh;
  }

  .photo-page {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${primaryColor};
    width: 1em;
    height: 1em;
    margin-left: 10em;
    margin-top: 0.5em;
    text-align: center;
    padding: 0.4em;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    z-index: 10;
  }

  .no-products {
    text-align: center;
    padding: 32vh 0;
  }
`
export default ProductCard
