import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

import RawFooter from './RawFooter'

const Footer = styled(RawFooter)`
  display: flex;
  justify-content: flex-end;

  hr {
    position: absolute;
    bottom: 11vh;
    width: 100%;
    border-width: 0;
    background-color: #e8e8e8;
    height: 1.5px;
  }

  .text {
    position: absolute;
    font-family: "Poppins", sans-serif;
    bottom: 4vh;
    font-size: 0.8rem;
    color: ${COLORS.LIGHT_GREY};
    margin-left: 10vh;
    margin-right: 10vh;
  }
`
export default Footer
