import { React } from 'react'

const RawFooter = ({ className, line }) => (
    <div className={className}>
      {line && <hr />}
      <p className="text">Powered by Pinflag</p>
    </div>
  )

export default RawFooter
