import { React, useState } from 'react'

import Card from '../../../shared/Card/Card.style'
import { PAGINATION_ICON_URL, NO_IMAGE_ICON } from '../../../../constants/urls'

const RawProductCard = ({ className, products, cost }) => {
  const [page, setPage] = useState(1)

  const changePage = (value) => {
    if (value > products.length) setPage(1)
    else if (value < 1) setPage(products.length)
    else setPage(value)
  }

  const productsQuantity = () => (
    products.reduce(
      (quantity, nextProduct) => quantity + nextProduct.quantity,
      0
    )
  )

  if (!products || !products[page - 1]) {
    return (
      <div>
        <Card className={className}>
          <div className="no-products">No hay productos que mostrar</div>
        </Card>
      </div>
    )
  }

  return (
    <div className={className}>
      <Card>
        <div className="photos">
          {products[page - 1].quantity > 1  && <div className="photo-page">{products[page - 1].quantity}</div>}
          <span className="left" onClick={() => changePage(page - 1)} role="button" tabIndex="0">
            {products.length > 1  && <img src={`${PAGINATION_ICON_URL}/icon-previous.svg`} alt="" className="icon" />}
          </span>
            <img
              src={
                products[page - 1].photoUrl &&
                products[page - 1].photoUrl[0]
                  ? products[page - 1].photoUrl[0].URLIMAGEN
                  : NO_IMAGE_ICON
              }
              alt=""
              className="product-img"
            />
            <span
              className="right"
              onClick={() => changePage(page + 1)}
              role="button"
              tabIndex="0"
            >
              {products.length > 1  && <img src={`${PAGINATION_ICON_URL}/icon-next.svg`} alt="" className="icon right" />}
            </span>
        </div>
        <ul className="product-detail">
          <li>
            <span>Nombre: </span>
            {products[page - 1].productName}
          </li>
          <li>
            <span>Valor: </span>$ {products[page - 1].price}
          </li>
          <li>
            <span>SKU: </span>
            {products[page - 1].sku}
          </li>
        </ul>
        <ul>
          <li>
            <span>Total productos de la venta: </span>
            {productsQuantity()}
          </li>
          <li>
          <span>Total de la venta: </span>$ {cost}
          </li>
        </ul>
      </Card>
    </div>
  )
}

export default RawProductCard
