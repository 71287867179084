import React, { useState, useEffect, useContext, useRef } from 'react'
import { GrCircleInformation } from 'react-icons/gr'
import { useHistory, useLocation } from 'react-router'
import { toast, Toaster } from 'react-hot-toast'
import axios from 'axios'
import Reaptcha from 'reaptcha'

import Modal from '../shared/Modal/Modal.style'
import Footer from '../shared/Footer/Footer.style'
import RawContext from '../Tracking/components/Context/RawContext'
import { login, setCompanyInfo } from '../../helper'
import {
  SEARCH_ICON,
  BUCKET_URL,
  TRACKING_ICON_SEARCH,
  MAIL_TEST_ICON,
  PINFLAG_PRIMARY_COLOR,
  MAIL_TEST_ICON_2,
  COMPANY_API_URL
} from '../../constants/urls'
import RawLoader from '../shared/Loader/RawLoader'
import SearchBarStyles from './SearchBar.style'

const SearchBar = ({ placeholder }) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorCaptcha, setErrorCaptcha] = useState('')
  const { openPopUp, setOpenPopUp } = useContext(RawContext)
  const baseIconPath = localStorage.getItem('baseIconPath')
  const timestamp = localStorage.getItem('timestamp')
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token') || process.env.REACT_APP_API_KEY
  const captchaRef = useRef(null)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [readyCaptcha, setReadyCaptcha] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setOpenPopUp(false)
    const setCompanyData = async () => {
      try {
        login(token)
        const companyInfoResponse = await axios.get(
          `${COMPANY_API_URL}/company/personalization`, {
          headers: { 'x-api-key': `${token}` }
        }
        )
        await setCompanyInfo(companyInfoResponse.data)
      } catch (error) {
        console.error(error)
        localStorage.setItem('baseIconPath', 'icons')
        localStorage.setItem('primaryColor', PINFLAG_PRIMARY_COLOR)
        localStorage.setItem('secondaryColor', '#173554')
        localStorage.setItem('timestamp', '')
      } finally {
        setLoading(false)
      }
    }
    setCompanyData()
    return () => {
      setLoading(true)
      setOpenPopUp(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (openPopUp) {
      toast.error('Orden no encontrada. Ingrese su orden nuevamente.', {
        style: {
          display: 'flex',
          backgroundColor: '#FF8080',
          color: 'white',
          fontFamily: 'Poppins'
        }
      })
    }
  }, [openPopUp])

  const verify = () => {
    captchaRef.current.getResponse().then(response => {
      setCaptchaToken(response)
    })
    setErrorCaptcha('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (captchaToken) {
      try {
        let orderCode = searchBarInput
        orderCode = orderCode.replace('#', '')
        if (orderCode.trim() === '') {
          toast.error('Inserte un número de orden.', {
            style: {
              backgroundColor: '#FF8080',
              color: 'white',
              fontFamily: 'Poppins'
            }
          })
        } else {
          history.push(`tracking/${orderCode}`)
          setSearchBarInput('')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setErrorCaptcha('Demuestra que no eres un robot')
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }

  const handleClick = () => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const primaryColor = localStorage.getItem('primaryColor')
  const secondaryColor = localStorage.getItem('secondaryColor')

  if (loading) {
    return (
      <SearchBarStyles>
        <div className="loader">
          <RawLoader />
        </div>
      </SearchBarStyles>
    )
  }

  return (
    <SearchBarStyles primaryColor={primaryColor} secondaryColor={secondaryColor}>
      <div className="container">
        <div>
          <form className="form-container" onSubmit={(e) => handleSubmit(e)}>
            <img
              className="tracking-icon"
              alt="tracking-icon"
              src={`${BUCKET_URL}/${baseIconPath}/${TRACKING_ICON_SEARCH.replace(/\.[^/.]+$/, '')}${timestamp}.png`}
            />
            <div>
              <p className="title-text">Rastrea tu compra</p>
              <div className="search-bar-container">
                <div className="search-bar-container-full">
                  <div className="search-bar-input">
                    <div className="search-bar-icon">
                      <img
                        className="icon-input"
                        alt="icon-input"
                        src={SEARCH_ICON}
                      />
                    </div>
                    <input
                      type="text"
                      placeholder={placeholder}
                      value={searchBarInput}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="button-container">
                    <div className="button-input">
                      <span onClick={readyCaptcha ? handleSubmit : undefined} role="button" tabIndex="0">Rastrear</span>
                    </div>
                    <Toaster />
                    <GrCircleInformation
                      className="icon-information"
                      onClick={() => handleClick()}
                    />
                  </div>
                </div>
                <div className="reCaptcha">
                  <Reaptcha
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                    onVerify={verify}
                    onLoad={() => setReadyCaptcha(true)}
                    onExpire={() => setCaptchaToken(null)}
                  />
                  <div className="captcha-error">{errorCaptcha}</div>
                </div>
              </div>
              <div>
                <p className="error">
                  Número de orden no encontrado. Intentelo nuevamente.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isModalOpen && (
        <Modal handleClose={handleClose} show={isModalOpen}>
          <span className="modal-text">
            El Nº de orden lo puedes encontrar en el mail de confirmación de tu
            compra ó en la boleta electrónica:
          </span>
          <br />
          <div className='images-container'>
            <img src={MAIL_TEST_ICON} alt="" className="image-modal" />
            <img src={MAIL_TEST_ICON_2} alt="" className="image-modal" />
          </div>
        </Modal>
      )}
      <Footer line />
    </SearchBarStyles>
  )
}

SearchBar.defaultProps = {
  placeholder: 'N° de orden'
}
export default SearchBar
