import styled from 'styled-components'

import RawModal from './RawModal'

const Modal = styled(RawModal)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;

  ${(props) => (props.show ? 'display: block;' : 'display: none;')}

  .content {
    position: fixed;
    top: 3%;
    left: 20%;
    height: auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px darkgray;
    padding: 1em;
    width: auto;
    margin-top: 1rem;
    max-width: 800px;

    @media only screen and (max-width: 1200px) {
      top: 1%;
      left: 20%;
    }

    @media only screen and (max-width: 950px) {
      left: 0;
    }

  }

  .close-button {
    width: 10px;
    margin-left: auto;
    margin-right: 1em;
    cursor: pointer;
    font-size: 32px;
    font-weight: 200;
    color: DarkGray;
    font-family: "Poppins", sans-serif;
  }

  .align-modal-button-center {
    display: flex;
    justify-content: center;
  }

  .image-modal {
  }
`

export default Modal
