import React from 'react'

import { getTimeFromDate, shortDate } from '../../../../utils/dates'
import { clickAndCollectStates, homeDeliveryStates } from '../../../../helpers/states'
import { PACKAGE_STATUSES, TO_CLIENT_STATUS } from '../../../../constants/packageStatus'

import TimeLineStyles from './TimeLine.style'

const TimeLine = ({ packageHistory, clickAndCollect, currentStatus }) => {
  const currentStatusIndex = PACKAGE_STATUSES.indexOf(TO_CLIENT_STATUS[currentStatus])

  const getTimes = (statusValue) => {
    const statusLog = packageHistory.find(
      (log) =>
        PACKAGE_STATUSES.indexOf(log.packageStatus) >=
        PACKAGE_STATUSES.indexOf(statusValue)
    )

    if (statusLog) return [shortDate(statusLog.date), getTimeFromDate(statusLog.date)]

    return null
  }

  const baseIconPath = localStorage.getItem('baseIconPath')
  const timestamp = localStorage.getItem('timestamp')

  const states = clickAndCollect ? clickAndCollectStates(baseIconPath, timestamp) : homeDeliveryStates(baseIconPath, timestamp)

  return (
    <TimeLineStyles
      clickAndCollect={clickAndCollect}
      primaryColor={localStorage.getItem('primaryColor')}
    >
      {currentStatus && (
        <div style={{ width: '55%' }}>
          <div className="timeline">
            {states.map((status) => (
              <div
                key={status.value}
                className={`item ${
                  PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                    ? 'active'
                    : 'inactive'
                }`}
              >
                <img
                  src={
                    PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                      ? status.activeIcon
                      : status.inactiveIcon
                  }
                  alt={status.textSales}
                  className="icon"
                />
                <span
                  className={`text-${
                    PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                      ? 'active'
                      : 'inactive'
                  }`}
                >
                  {status.textSales}
                </span>

                <div
                  className={`${
                    PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                      ? 'show'
                      : 'hidden'
                  }`}
                >
                  {getTimes(status.value)?.map((timeLog) => (
                    <div
                      key={timeLog}
                      className={`${
                        PACKAGE_STATUSES.indexOf(status.value) <=
                        currentStatusIndex
                          ? 'show'
                          : 'hidden'
                      }`}
                    >
                      {timeLog}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </TimeLineStyles>
  )
}

export default TimeLine
