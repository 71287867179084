import React, { useMemo, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import ROUTES from './constants/routes'
import SearchBar from './components/SearchBar/SearchBar'
import RawContext from './components/Tracking/components/Context/RawContext'
import './App.css'
import Tracking from './components/Tracking/Tracking'

const App = () => {
  const [openPopUp, setOpenPopUp] = useState(false)

  const popUpState = useMemo(
    () => ({ openPopUp, setOpenPopUp }),
    [openPopUp]
  )

  return (
    <div className="App">
      <RawContext.Provider value={popUpState}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to={ROUTES.SEARCH} />
            </Route>
            <Route path={ROUTES.SEARCH} component={SearchBar} />
            <Route path={ROUTES.TRACKING} component={Tracking} />
          </Switch>
        </Router>
      </RawContext.Provider>
    </div>
  )
}

export default App
