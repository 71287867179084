export const PACKAGE_BOUGHT = 'bought'
export const PACKAGE_PROCESSED = 'processed'
export const PACKAGE_IN_TRANSIT = 'in_transit'
export const PACKAGE_ON_DELIVERY = 'on_delivery'
export const PACKAGE_RECEIVED = 'received'
export const PACKAGE_STORED = 'stored'
export const PACKAGE_PICKED_UP = 'picked_up'
export const PACKAGE_DELIVERED = 'delivered'
export const PACKAGE_PARTIAL_DELIVERY = 'partial_delivery'
export const PACKAGE_ON_RETURN = 'on_return'
export const PACKAGE_RETURNED = 'returned'
export const PACKAGE_TOTAL_INCIDENT = 'total_incident'

export const PACKAGE_STATUSES = [
  PACKAGE_BOUGHT,
  PACKAGE_PROCESSED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_RECEIVED,
  PACKAGE_STORED,
  PACKAGE_PICKED_UP,
  PACKAGE_DELIVERED,
  PACKAGE_PARTIAL_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_RETURNED,
  PACKAGE_TOTAL_INCIDENT
]

export const TO_CLIENT_STATUS = {
  [PACKAGE_BOUGHT]: PACKAGE_BOUGHT,
  [PACKAGE_PROCESSED]: PACKAGE_PROCESSED,
  [PACKAGE_IN_TRANSIT]: PACKAGE_IN_TRANSIT,
  [PACKAGE_ON_DELIVERY]: PACKAGE_IN_TRANSIT,
  [PACKAGE_RECEIVED]: PACKAGE_RECEIVED,
  [PACKAGE_STORED]: PACKAGE_RECEIVED,
  [PACKAGE_PICKED_UP]: PACKAGE_RECEIVED,
  [PACKAGE_DELIVERED]: PACKAGE_DELIVERED,
  [PACKAGE_PARTIAL_DELIVERY]: PACKAGE_DELIVERED,
  [PACKAGE_ON_RETURN]: PACKAGE_ON_RETURN,
  [PACKAGE_RETURNED]: PACKAGE_RETURNED,
  [PACKAGE_TOTAL_INCIDENT]: PACKAGE_TOTAL_INCIDENT
}
