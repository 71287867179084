export const SIZES = {
  SIDEBAR_WIDTH: '16%'
}

export const COLORS = {
  WHITE: ' #EBF4F7',
  BLUE5: '#acd1cf',
  BLACK: '#072130',

  GREEN: '#00cc66',
  RED: '#e23b3b',
  PURPLE: '#6666ff',
  YELLOW: '#fcb527',

  DARK_GREY: '#566076',
  LIGHT_GREY: '#c0d5da',

  STATE_1: '#6666FF',
  STATE_2: '#B563E5',
  STATE_3: '#FFCE00',
  STATE_4: '#155DB1',
  STATE_5: '#2196F3'
}
