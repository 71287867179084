export const API_URL = process.env.REACT_APP_API_URL
export const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API_URL
export const BUCKET_URL = process.env.REACT_APP_BASE_BUCKET_URL
export const TRACKING_ICONS_URL = process.env.REACT_APP_ICONS_TRACKING_BASE_URL
export const PINFLAG_PRIMARY_COLOR = '#33CCCC'
export const INACTIVE_ICONS_URL = `${TRACKING_ICONS_URL}/main-states/inactive`
export const PAGINATION_ICON_URL = `${TRACKING_ICONS_URL}/pagination`
export const NO_IMAGE_ICON = `${TRACKING_ICONS_URL}/sales/no-image.png`
export const SEARCH_ICON = `${TRACKING_ICONS_URL}/search-icon.svg`
export const CLIPBOARD_ICON = `${TRACKING_ICONS_URL}/tracking/clipboard.png`
export const MAIL_TEST_ICON = `${TRACKING_ICONS_URL}/mails-test.png`
export const MAIL_TEST_ICON_2 = `${TRACKING_ICONS_URL}/mails-test-2.jpeg`
export const TRACKING_ICON_SEARCH = 'tracking-icon.png'
export const TRACKING_FULL_ICON = 'tracking.png'
export const TRACKING_ACTIVE_1 = 'tracking-state-1.png'
export const TRACKING_ACTIVE_2 = 'tracking-state-2.png'
export const TRACKING_ACTIVE_3 = 'tracking-state-3.png'
export const TRACKING_ACTIVE_4 = 'tracking-state-4.png'
export const TRACKING_ACTIVE_5 = 'tracking-state-5.png'
export const TRACKING_INACTIVE_1 = '1.svg'
export const TRACKING_INACTIVE_2 = '2.svg'
export const TRACKING_INACTIVE_3 = '3.svg'
export const TRACKING_INACTIVE_4 = '4.svg'
export const TRACKING_INACTIVE_5 = '5.svg'
