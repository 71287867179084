import React from 'react'
import styled from 'styled-components'

const primaryColor = localStorage.getItem('primaryColor')

const RawLoader = () => (
    <Loader>
      <div className="lds-dual-ring flex" data-testid="loader" />
    </Loader>
  )

export default RawLoader

const Loader = styled.div`
  .lds-dual-ring {
    width: 70px;
    height: 70px;
  }
  .lds-dual-ring:after {
    content: " ";
    width: 44px;
    height: 44px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: ${primaryColor} transparent ${primaryColor} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  span {
    position: absolute;
  }

  span img {
    width: 4rem;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
