import styled from 'styled-components'

import { COLORS } from '../../../../constants/styles'

const TimeLineStyles = styled.div`
  display: flex;
  height: 11em;
  width: 100%;
  justify-content: space-around;
  font-size: 12px;
  overflow-x: hidden;

  @media only screen and (max-width: 404px) {
    font-size: 10px;
  }

  .timeline {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hidden {
    display: none;
  }

  .timeline .item {
    position: relative;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 5px;
    top: 4em;
  }

  .text-active {
    color: ${(props) => props.primaryColor};
    font-weight: 600;
  }

  .text-inactive {
    color: ${COLORS.LIGHT_GREY};
  }

  .item:before {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    margin: 0 auto 5px auto;
  }

  .active:before {
    border: 2px solid ${(props) => props.primaryColor};
    background: ${(props) => props.primaryColor};
  }

  .inactive:before {
    border: 2px solid ${COLORS.LIGHT_GREY};
    background-color: ${COLORS.LIGHT_GREY};
    opacity: 1;
  }

  .item:not(:first-child)::after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    margin: 0;
    position: absolute;
    top: 5.5px;
    right: calc(50% + 6px);
    z-index: -1;

    @media only screen and (max-width: 900px) {
      ${(props) =>
      props.clickAndCollect ?
      `
        left: -45px;
      ` :''
    }
    }
    
    @media only screen and (max-width: 404px) {
      width: 100%;
      left: -28px;
    }

    @media only screen and (max-width: 390px) {
      width: 94%;
      left: -37px;
    }

    @media only screen and (max-width: 380px) {
      width: 94%;
      left: -35px;
    }

  }

  .active:not(:first-child)::after {
    background: ${(props) => props.primaryColor};
    width: 100%;

    @media only screen and (max-width: 740px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      width: 100%;
      left: -33px;
      ` : ''
    }

    @media only screen and (max-width: 570px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      width: 100%;
      left: -30px;
      ` :
      `
      width: 97%;
      left: -34px;
      `
    }
    }
  }

  @media only screen and (max-width: 404px) {
      ${(props) =>
      props.clickAndCollect ?
      '' :
      `
      width: 97%;
      left: -28px;
      `
    }
  }
}

  .inactive:not(:first-child)::after {
    background: ${COLORS.LIGHT_GREY};
    opacity: 1;
    width: 100%;
    
    @media only screen and (max-width: 740px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      width: calc(100%);
      left: -40px;
      ` : ''
    }
    }

    @media only screen and (max-width: 690px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      width: calc(100%);
      left: -35px;
      ` :
      `
      width: calc(100%);
      left: -40px;
      `
    }
    }

    @media only screen and (max-width: 570px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      width: 100%;
      left: -35px;
      ` :
      `
      width: 100%;
      left: -32px;
      `
    }
    }

    @media only screen and (max-width: 404px) {
      ${(props) =>
      props.clickAndCollect ?
      `
      left: -30px;
      ` : ''
    }
    }
  }

  .icon {
    position: absolute;
    top: -4em;
    left: calc(50% - 1.5em);
    width: 3em;
  }
`

export default TimeLineStyles
