import { React } from 'react'

const RawModal = ({ className, handleClose, children }) => {

  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    handleClose()
  }

  return (
    <div
      className={className}
      onClick={(e) => clickOutside(e)}
      role="button"
      tabIndex="0"
    >
      <div className="content">
        <div
          className="close-button"
          onClick={handleClose}
          role="button"
          tabIndex="0"
        >
          x
        </div>
        {children}
      </div>
    </div>
  )
}

export default RawModal
