/* eslint-disable react-func/max-lines-per-function */
import { PACKAGE_BOUGHT, PACKAGE_DELIVERED, PACKAGE_IN_TRANSIT, PACKAGE_PROCESSED, PACKAGE_RECEIVED } from '../constants/packageStatus'
import {
  BUCKET_URL,
  INACTIVE_ICONS_URL,
  TRACKING_ACTIVE_1,
  TRACKING_ACTIVE_2,
  TRACKING_ACTIVE_3,
  TRACKING_ACTIVE_4,
  TRACKING_ACTIVE_5,
  TRACKING_INACTIVE_1,
  TRACKING_INACTIVE_2,
  TRACKING_INACTIVE_3,
  TRACKING_INACTIVE_4,
  TRACKING_INACTIVE_5
} from '../constants/urls'

export const baseStates = (baseIconPath, timestamp) => [
  {
    value: PACKAGE_BOUGHT,
    name: 'Comprado',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_1.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_1}`,
    textSales: 'Exitosa'
  },
  {
    value: PACKAGE_PROCESSED,
    name: 'Procesado',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_2.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_2}`,
    textSales: 'Procesada'
  },
  {
    value: PACKAGE_IN_TRANSIT,
    name: 'En camino',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_3.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_3}`,
    textSales: 'Enviada'
  }
]

export const homeDeliveryStates = (baseIconPath, timestamp) => [
  ...baseStates(baseIconPath, timestamp),
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_5.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_5}`,
    textSales: 'Entregada'
  }
]

export const clickAndCollectStates = (baseIconPath, timestamp) => [
  ...baseStates(baseIconPath, timestamp),
  {
    value: PACKAGE_RECEIVED,
    name: 'Recepcionado',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_4.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_4}`,
    textSales: 'Recepcionada'
  },
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${BUCKET_URL}/${baseIconPath}/${TRACKING_ACTIVE_5.replace(
      /\.[^/.]+$/,
      ''
    )}${timestamp}.png`,
    inactiveIcon: `${INACTIVE_ICONS_URL}/${TRACKING_INACTIVE_5}`,
    textSales: 'Entregada'
  }
]
