import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast, Toaster } from 'react-hot-toast'

import {
  API_URL,
  PAGINATION_ICON_URL,
  CLIPBOARD_ICON,
  BUCKET_URL,
  TRACKING_FULL_ICON
} from '../../constants/urls'
import RawLoader from '../shared/Loader/RawLoader'
import Card from '../shared/Card/Card.style'

import ProductCard from './components/ProductCard/ProductCard.style'
import RawContext from './components/Context/RawContext'
import TrackingStyles from './Tracking.style'
import TimeLine from './components/TimeLine/TimeLine'

const Tracking = () => {
  const { saleId } = useParams()
  const [sale, setSale] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [moreInfo, setMoreInfo] = useState(false)
  const { setOpenPopUp } = useContext(RawContext)
  const baseIconPath = localStorage.getItem('baseIconPath')
  const timestamp = localStorage.getItem('timestamp')

  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    if (token) {
      axios.get(`${API_URL}/sales/${saleId}?packageType=ecommerce&origin=tracking`, {
          headers: { 'api-key': `${token}` }
        })
        .then((res) => {
          setSale(res.data)
        })
        .catch((error) => {
          setOpenPopUp(true)
          console.log(error)
          history.goBack()
        })
        .finally(()=> setIsLoading(false))
    } else {
      setIsLoading(false)
      setOpenPopUp(true)
      history.goBack()
    }
  }, [saleId, history, setOpenPopUp])

  const handleClick = () => {
    setMoreInfo(true)
  }

  function handleBack(e) {
    e.preventDefault()
    history.goBack()
  }

  const primaryColor = localStorage.getItem('primaryColor')

  return (
    <TrackingStyles primaryColor={primaryColor}>
      <div className="container">
        {isLoading || !sale ? (
          <div className="loader">
            <RawLoader />
          </div>
        ) : (
          <div className="flex-container">
            <div className="holding">
              <div className="back">
                <img src={`${PAGINATION_ICON_URL}/icon-previous.svg`} alt="" />
                <span onClick={handleBack} role="button" tabIndex="0">
                  Volver
                </span>
              </div>
              <img
                className="logo-holding"
                src={`${BUCKET_URL}/${baseIconPath}/${TRACKING_FULL_ICON.replace(
                  /\.[^/.]+$/,
                  ''
                )}${timestamp}.png`}
                alt=""
              />
            </div>
            <div className="grid-item">
              <TimeLine
                packageHistory={sale.packageHistory}
                clickAndCollect={sale.clickAndCollect}
                currentStatus={sale.packageStatus}
              />
              <div className="data-grid">
                <Card className="card-data-item">
                  <ul className="data-item">
                    <li>
                      <span>Orden de venta: </span>
                      {sale.saleOrder}
                    </li>
                    <li>
                      <span>ID Pinflag: </span>
                      {sale.pinflagId}
                    </li>
                    {sale.trackingNumber && (
                      <li>
                        <span>Orden de transporte: </span>
                        {sale.trackingNumber}
                      </li>
                    )}
                  </ul>
                </Card>
                <Card className="card-data-item">
                  <ul className="data-item">
                    <li>
                      <span>Región: </span>
                      {sale.state}
                    </li>
                    <li>
                      <span>Comuna: </span>
                      {sale.city}
                    </li>
                    <li>
                      <span>Costo envío: </span>${' '}
                      {sale.shippingPaid || sale.shippingCost || '-'}
                    </li>
                  </ul>
                </Card>
                <Card className="card-data-item">
                  <ul className="data-item">
                    <li>
                      <span>Modalidad pedido: </span>
                      {sale.clickAndCollect ? 'Click and Collect' : 'Despacho'}
                    </li>
                    <li>
                      <span>Courier: </span>
                      {sale.courier?.displayName}
                    </li>
                    <li>
                      <span>Dirección: </span>
                      {sale.deliveryAddress || '-'}
                    </li>
                  </ul>
                </Card>
              </div>
              <div className="subgrid-container">
                <div className="grid-item grid-prod">
                  <ProductCard
                    cost={sale.totalProductsPrice}
                    products={sale.products}
                  />
                </div>
                {sale.courier?.trackingUrl && sale.trackingNumber && (
                  <div className="grid-item grid-courier">
                    {!moreInfo ? (
                      <div className="grid-item grid-courier">
                        <span
                          onClick={handleClick}
                          className="item-info"
                          role="button"
                          tabIndex="0"
                        >
                          ¿Quieres saber más?
                        </span>
                      </div>
                    ) : (
                      <div className="grid-item grid-courier">
                        <Card>
                          <div className="content-card">
                            <ul className="ul-card">
                              <li>
                                Presiona aca&nbsp;
                                <a
                                  className="span-card"
                                  href={() => false}
                                  onClick={() =>
                                    window.open(
                                      `${sale.courier?.trackingUrl}${
                                        ['globaltracking', 'recibelo'].includes(
                                          sale.courier.name
                                        )
                                          ? ''
                                          : sale.trackingNumber
                                      }`
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                  }}
                                >
                                  <b>{sale.courier?.displayName}</b>
                                </a>
                                <br />
                                <br />y utiliza este código para saber mas de tu
                                pedido:
                                <br />
                                <CopyToClipboard text={sale.trackingNumber}>
                                  <span
                                    className="span-card copy-item"
                                    onClick={() =>
                                      toast('Orden de transporte copiada!')
                                    }
                                    role="button"
                                    tabIndex="0"
                                  >
                                    {sale.trackingNumber}
                                    <img
                                      src={CLIPBOARD_ICON}
                                      alt=""
                                      className="clipboard"
                                    />
                                  </span>
                                </CopyToClipboard>
                                <Toaster />
                              </li>
                            </ul>
                          </div>
                        </Card>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </TrackingStyles>
  )
}

export default Tracking
